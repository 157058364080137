/*!

 =========================================================
 * Material Kit React v1.8.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import "core/variables";
@import "core/mixins";

// Core Components
@import "core/misc";

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-slick";

//@import url(https://db.onlinewebfonts.com/c/6e2e51bfb9b3091d8885ad6656dd0911?family=Menco);

/*
      www.OnlineWebFonts.Com 
      You must credit the author Copy this link on your web 
      <div>Font made from <a href="https://www.onlinewebfonts.com">oNline Web Fonts</a>is licensed by CC BY 3.0</div>
      OR
      <a href="https://www.onlinewebfonts.com">oNline Web Fonts</a>
*/
// @font-face {font-family: "Menco";
//     src: url("https://db.onlinewebfonts.com/t/6e2e51bfb9b3091d8885ad6656dd0911.eot"); /* IE9*/
//     src: url("https://db.onlinewebfonts.com/t/6e2e51bfb9b3091d8885ad6656dd0911.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
//     url("https://db.onlinewebfonts.com/t/6e2e51bfb9b3091d8885ad6656dd0911.woff2") format("woff2"), /* chrome firefox */
//     url("https://db.onlinewebfonts.com/t/6e2e51bfb9b3091d8885ad6656dd0911.woff") format("woff"), /* chrome firefox */
//     url("https://db.onlinewebfonts.com/t/6e2e51bfb9b3091d8885ad6656dd0911.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
//     url("https://db.onlinewebfonts.com/t/6e2e51bfb9b3091d8885ad6656dd0911.svg#Menco") format("svg"); /* iOS 4.1- */
// }
// @import MencoEOT from '../assets/fonts/6e2e51bfb9b3091d8885ad6656dd0911.eot';
// @import MencoWOFF from '../assets/fonts/6e2e51bfb9b3091d8885ad6656dd0911.woff';
// @import MencoWOFF2 from '../assets/fonts/6e2e51bfb9b3091d8885ad6656dd0911.woff2';
// @import MencoTTF from '../assets/fonts/6e2e51bfb9b3091d8885ad6656dd0911.ttf';
// @import MencoSVG from '../assets/fonts/6e2e51bfb9b3091d8885ad6656dd0911.svg';
$font-path: '../fonts';
$font-name: '6e2e51bfb9b3091d8885ad6656dd0911';

@font-face {font-family: "Menco";
    src: url("#{$font-path}/#{$font-name}.eot"); /* IE9*/
    src: url("#{$font-path}/#{$font-name}.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("#{$font-path}/#{$font-name}.woff2") format("woff2"), /* chrome firefox */
    url("#{$font-path}/#{$font-name}.woff") format("woff"), /* chrome firefox */
    url("#{$font-path}/#{$font-name}.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("#{$font-path}/#{$font-name}.svg#Menco") format("svg"); /* iOS 4.1- */
}
